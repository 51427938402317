import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';
import { CustomCookieService } from '@uc/shared/authentication';
import { Observable } from 'rxjs';
import { ClientDetails, StudentDetails } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class UserDetailsApiService {
	constructor(
		private _http: HttpClient,
		private _customCookieSrv: CustomCookieService,
		private _apiSrv: EnvironmentApiService,
	) {}

	getStudentDetails(token?: string): Observable<StudentDetails | null> {
		const _token = token ?? this._customCookieSrv.get('token');
		const headers = new HttpHeaders({
			Authorization: `Bearer ${_token}`,
		});

		return this._http.get<StudentDetails>(`${this._apiSrv.API_URL}student`, {
			headers,
			transferCache: false,
		});
	}

	getTokenBySSO(id: string): Observable<{ token: string }> {
		const params = {
			id,
		};

		return this._http.post<{ token: string }>(
			`${this._apiSrv.API_URL}account/login-by-sso`,
			params,
			{ transferCache: false },
		);
	}

	getClientDetails(token?: string): Observable<ClientDetails | null> {
		const _token = token ?? this._customCookieSrv.get('token');

		const headers = new HttpHeaders({
			Authorization: `Bearer ${_token}`,
		});

		return this._http.get<ClientDetails>(`${this._apiSrv.API_URL}client`, {
			headers,
			transferCache: false,
		});
	}
}
