export const DEGREE_LEVEL_TYPE_MAPPER = {
	undergraduate: 'ALL_UNDERGRADUATE',
	degrees: 'BACHELOR',
	foundation: 'FOUNDATION',
	'undergraduate-certificate-diplomas': 'UG_CERTIFICATE_AND_DIPLOMA',
	hnd: 'HND',
	hnc: 'HNC',
	'access-to-higher-education': 'ACCESS_TO_HIGHER_EDUCATION',
	postgraduate: 'ALL_POSTGRADUATE',
	masters: 'MASTER',
	phd: 'PHD',
	'postgraduate-certificate-diplomas': 'PG_CERTIFICATE_AND_DIPLOMA',
	pgce: 'PGCE',
	'postgraduate-certificate': 'PG_CERTIFICATE',
	'postgraduate-diploma': 'PG_DIPLOMA',
};
