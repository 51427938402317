import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormOptionsApiService } from '../form-options-api.service';
import {
	fetchCountryFormOptions,
	fetchSubjectFormOptions,
	loadFormOptionsError,
	updateCountryFormOptions,
	updateSubjectFormOptions,
} from './form-options.actions';
import {
	catchError,
	distinctUntilChanged,
	filter,
	map,
	of,
	switchMap,
	withLatestFrom,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { getCountryFormOptions, getSubjectFormOptions } from './form-options.selectors';

@Injectable()
export class StateFormOptionsEffects {
	constructor(
		private readonly _actions$: Actions,
		private _store: Store,
		private _formOptionsApiSrv: FormOptionsApiService,
	) {}

	fetchCountryFormOptions = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchCountryFormOptions),
			withLatestFrom(this._store.select(getCountryFormOptions)),
			distinctUntilChanged(),
			filter(([, countries]) => !countries || countries.length === 0),
			switchMap(() => this._formOptionsApiSrv.getCountries()),
			map((response) => updateCountryFormOptions({ countries: response })),
			catchError((error) => of(loadFormOptionsError({ error }))),
		),
	);

	fetchSubjectFormOptions = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchSubjectFormOptions),
			withLatestFrom(this._store.select(getSubjectFormOptions)),
			distinctUntilChanged(),
			filter(([, subjects]) => !subjects || subjects.length === 0),
			switchMap(() => this._formOptionsApiSrv.getSubjects()),
			map((response) => updateSubjectFormOptions({ subjects: response })),
			catchError((error) => of(loadFormOptionsError({ error }))),
		),
	);
}
