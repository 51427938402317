import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PagesApiService } from '../page-content.service';
import { fetchPageData, loadPageDataError, updatePageData } from './page-content.actions';
import { catchError, distinctUntilChanged, map, of, switchMap } from 'rxjs';

@Injectable()
export class StatePageDataEffects {
	constructor(
		private readonly _actions$: Actions,
		private _pagesApiSrv: PagesApiService,
	) {}

	fetchPageData = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchPageData),
			distinctUntilChanged(),
			switchMap((page) => {
				return this._pagesApiSrv.getPageContent(page.url).pipe(
					map((response) => updatePageData({ pageData: response })),
					catchError((error) => of(loadPageDataError(error))),
				);
			}),
		),
	);
}
