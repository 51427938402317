import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { DegreeQuizResult, DegreeQuizVersion } from '../models/degree-quiz.models';
import { isInAppBrowser, normalizePlatformName } from '@uc/utilities';
import { InAppBrowserPlatform } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class DegreeQuizGTMService {
	private dataLayerSrv = inject(DataLayerService);

	// whenever the user clicks a button to enter the quiz flow (e.g. on landing page, in secondary navigation, etc.)
	onQuizButtonClick(quizVersion: DegreeQuizVersion) {
		this.dataLayerSrv.push({
			eventName: 'quiz_button_click',
			quizVersion: quizVersion,
			event: 'quizButtonClick',
		});
	}

	// when user answers first question of quiz
	onQuizStart(
		quizVersion: DegreeQuizVersion,
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_start',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizVersion: quizVersion,
			event: 'quizStart',
		});
	}

	// when user clicks "yes"/"no"/"unsure" on a question
	onQuizQuestionAnswered(
		quizVersion: DegreeQuizVersion,
		questionNumber: number,
		answer: 'yes' | 'no' | 'unsure',
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_question_answered',
			quizVersion: quizVersion,
			quizQuestionNumber: questionNumber,
			quizAnswer: answer,
			event: 'quizQuestionAnswered',
		});
	}

	// when user clicks "next" after answering all questions
	onQuizCompleted(
		quizVersion: DegreeQuizVersion,
		timeToComplete: number,
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_completed',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizVersion: quizVersion,
			time: timeToComplete,
			event: 'quizCompleted',
		});
	}

	// when user enters the result page
	onQuizResultView(
		quizVersion: DegreeQuizVersion,
		results: DegreeQuizResult[],
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_results_view',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizVersion: quizVersion,
			quizResultOne: results[0].name,
			quizResultOneScore: results[0].percentage,
			quizResultTwo: results[1].name,
			quizResultTwoScore: results[1].percentage,
			quizResultThree: results[2].name,
			quizResultThreeScore: results[2].percentage,
			event: 'quizResultsView',
		});
	}

	// on the quiz results page, when the user clicks Take Our Full Quiz button
	onQuizContinueButtonClick(
		results: DegreeQuizResult[],
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_continue_button_click',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizResultOne: results[0].name,
			quizResultOneScore: results[0].percentage,
			quizResultTwo: results[1].name,
			quizResultTwoScore: results[1].percentage,
			quizResultThree: results[2].name,
			quizResultThreeScore: results[2].percentage,
			event: 'quizContinueButtonClick',
		});
	}

	// on the quiz result page / form when user clicks retake quiz button
	onQuizRetakeButtonClick(
		quizVersion: 'short' | 'long',
		results: DegreeQuizResult[],
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_retake_button_click',
			quizVersion: quizVersion,
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizResultOne: results[0].name,
			quizResultOneScore: results[0].percentage,
			quizResultTwo: results[1].name,
			quizResultTwoScore: results[1].percentage,
			quizResultThree: results[2].name,
			quizResultThreeScore: results[2].percentage,
			event: 'quizRetakeButtonClick',
		});
	}

	// on the Quiz Registration Page, the user fills in their first field
	onQuizRegistrationStart(
		quizVersion: DegreeQuizVersion,
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_registration_start',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizVersion: quizVersion,
			event: 'quizRegistrationStart',
		});
	}

	// when the user successfully submits the quiz registration form
	onQuizRegistrationComplete(
		quizVersion: DegreeQuizVersion,
		timeToComplete: number,
		userId: string,
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_registration_success',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			quizVersion: quizVersion,
			time: timeToComplete,
			userId: userId,
			event: 'quizRegistrationSuccess',
		});
	}

	onQuizSearchButtonClick(
		quizResult: string,
		quizResultScore: number,
		position: number,
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		this.dataLayerSrv.push({
			eventName: 'quiz_search_button_click',
			inAppBrowser: isInAppBrowser(inAppBrowserPlatform),
			inAppBrowserPlatform: normalizePlatformName(inAppBrowserPlatform),
			searchedQuizResult: quizResult,
			searchedQuizResultScore: quizResultScore,
			position: position,
			event: 'quizSearchButtonClick',
		});
	}
}
