import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomCookieService } from '@uc/shared/authentication';
import { configureSource, EnvironmentApiService } from '@uc/utilities';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class VisitWebsiteBtnApiService {
	private _userTrackingUrl = 'Users/uc_app_user_visit_website.php';

	constructor(
		private _http: HttpClient,
		private _customCookieSrv: CustomCookieService,
		private _apiSrv: EnvironmentApiService,
	) {}

	/**
	 * @param uniId ID of uni tracked
	 * @param source name of uni or course name
	 * @param sourceType uni | course
	 * @returns int (I assume ID of last inserted row in DB)
	 */
	visitWebsiteClick(
		uniId: number,
		source: string,
		sourceType: SourceTrackingTypes = 'uni',
	) {
		const userId = this._customCookieSrv.get('user_id');
		const params = {
			uid: uniId,
			id: userId ? userId : 0,
			source: configureSource(source, sourceType),
		};

		lastValueFrom(
			this._http.post(this._apiSrv.PHP_URL + this._userTrackingUrl, params),
		).then(console.log);
	}
}
