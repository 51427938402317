import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HECoSSubjectsApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getHECoSSubjects() {
		return firstValueFrom(
			this._http.get<{ subjects: string[] }>(
				`${this._apiSrv.JSON_URL}course-search/hecos-subjects.json`,
			),
		);
	}
}
