import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
	catchError,
	combineLatest,
	distinctUntilChanged,
	filter,
	map,
	switchMap,
	withLatestFrom,
} from 'rxjs';

import {
	fetchRecommendedUniversities,
	loadStateRecommendedUniversitiesFailure,
	updateRecommendedUniversities,
} from './recommended-universities.actions';
import {
	RecommendedClearingUniversity,
	RecommendedUniversity,
	UppercaseProductEnum,
} from '@uc/web/shared/data-models';
import { getStateRecommendedUniversities } from './recommended-universities.selectors';
import { Store } from '@ngrx/store';
import { RecommendedUniversitiesApiService } from '../recommended-universities-api.service';

@Injectable()
export class StateRecommendedUniversitiesEffects {
	constructor(
		private readonly _actions$: Actions,
		private _recommendedUnisApiSrv: RecommendedUniversitiesApiService,
		private _store: Store,
	) {}

	fetchRecommendedUniversities = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchRecommendedUniversities),
			distinctUntilChanged(),
			withLatestFrom(this._store.select(getStateRecommendedUniversities)),
			filter(([, recommendedUniversities]) => !recommendedUniversities),
			switchMap(() =>
				combineLatest([
					this._recommendedUnisApiSrv.getRecommendedUnis(
						UppercaseProductEnum.Undergraduate,
					),
					this._recommendedUnisApiSrv.getRecommendedUnis(
						UppercaseProductEnum.Postgraduate,
					),
					this._recommendedUnisApiSrv.getClearingRecommendedUnis(),
				]),
			),
			map(([undergraduate, postgraduate, clearing]) => {
				const formattedClearingUnis = _formatClearingUni(clearing);
				return updateRecommendedUniversities({
					universities: {
						undergraduate,
						postgraduate,
						clearing: formattedClearingUnis,
					},
				});
			}),
			catchError(async (error) => loadStateRecommendedUniversitiesFailure(error)),
		),
	);
}

function _formatClearingUni(clearingUnis: RecommendedClearingUniversity[]) {
	const result: RecommendedUniversity[] = [];
	clearingUnis.forEach((uni) => {
		result.push({
			id: uni.id,
			name: uni.name,
			slug: uni.slug,
			logo: uni.logo,
			paid_features: uni.paid_features,
			saved: uni.saved,
			courses_count: uni.courses_count,
			phone_number: uni.phone_number,
			address: {
				region: uni.region,
			},
			profile: {
				cover_image: uni.cover_image,
				external_url: uni.external_url,
			},
			stats: {
				recommended_percentage: uni.recommended_percentage,
			},
			order: null,
			privacy_policy_url: uni.privacy_policy_url,
		});
	});

	return result;
}
