import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PersonalStatementKeywordsApiService } from '../personal-statement-keywords-api.service';
import {
	distinctUntilChanged,
	withLatestFrom,
	filter,
	switchMap,
	map,
	catchError,
	tap,
} from 'rxjs';

import {
	fetchPersonalStatementKeywords,
	loadStatePersonalStatementKeywordsFailure,
	resetStateOnNotFound,
	updatePersonalStatementCategory,
	updatePersonalStatementKeywords,
} from './personal-statement-keywords.actions';
import {
	getPersonalStatementCategory,
	getPersonalStatementKeywords,
} from './personal-statement-keywords.selectors';

@Injectable()
export class StatePersonalStatementKeywordsEffects {
	constructor(
		private readonly _actions$: Actions,
		private _personalStatementsApiSrv: PersonalStatementKeywordsApiService,
		private _store: Store,
	) {}

	fetchPersonalStatementKeywords = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchPersonalStatementKeywords),
			distinctUntilChanged(),
			withLatestFrom(
				this._store.select(getPersonalStatementCategory),
				this._store.select(getPersonalStatementKeywords),
			),
			filter(([payload, category]) => !category || payload.category !== category),
			tap(([payload]) =>
				this._store.dispatch(
					updatePersonalStatementCategory({ category: payload.category }),
				),
			),
			switchMap(([{ category }]) =>
				this._personalStatementsApiSrv.getKeywords(category),
			),
			map((payload) => updatePersonalStatementKeywords({ keywords: payload })),
			catchError((error, observable) => {
				if (error.status === 404) {
					this._store.dispatch(resetStateOnNotFound());
				}
				this._store.dispatch(loadStatePersonalStatementKeywordsFailure(error));
				return observable;
			}),
		),
	);
}
