import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	withLatestFrom,
	distinctUntilChanged,
	filter,
	switchMap,
	map,
	catchError,
	of,
	forkJoin,
} from 'rxjs';

import { getPromoSlotsData } from './promo-slots.selectors';
import { PromoSlotsApiService } from '../promo-slots-api.service';
import {
	fetchPromoSlots,
	loadPromoSlotsError,
	updatePromoSlots,
} from './promo-slots.actions';

@Injectable()
export class StatePromoSlotsEffect {
	constructor(
		private readonly _actions$: Actions,
		private _store: Store,
		private _promoSlotApiSrv: PromoSlotsApiService,
	) {}

	fetchPromoSlots = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchPromoSlots),
			withLatestFrom(this._store.select(getPromoSlotsData)),
			distinctUntilChanged(),
			filter(([, cards]) => cards.default === null && cards.clearing === null),
			switchMap(([{ platform }]) => {
				return forkJoin({
					defaultSlots: this._promoSlotApiSrv.getDefaultPromoSlots(platform),
					cleSlots: this._promoSlotApiSrv.getClePromoSlots(),
				});
			}),
			map(({ defaultSlots, cleSlots }) =>
				updatePromoSlots({ defaultSlots, cleSlots }),
			),
			catchError((error) => of(loadPromoSlotsError({ error }))),
		),
	);
}
