import { Product, ProductAbbr, ProductEnum } from '@uc/web/shared/data-models';

export const determineTier = (uniId: number): 'default' | 1 | 2 => {
	if (tier1.includes(uniId)) {
		return 1;
	} else if (tier2.includes(uniId)) {
		return 2;
	}
	return 'default';
};

export const determineValue = (uniId: number): 'default' | '14' | '15' => {
	if (tier1.includes(uniId)) {
		return '14';
	} else if (tier2.includes(uniId)) {
		return '15';
	}
	return 'default';
};

export const isUserToNumber = (isUser: boolean): 0 | 1 => {
	return isUser ? 1 : 0;
};

export const abbreviateProduct = (product: Product | ''): ProductAbbr | 'default' => {
	if (product === ProductEnum.Undergraduate) return 'ug';
	else if (product === ProductEnum.Postgraduate) return 'pg';
	else if (product === ProductEnum.Clearing) return 'cle';
	else return 'default';
};

export const getProductByAbbreviation = (abbreviation: ProductAbbr) => {
	if (abbreviation === 'ug') return ProductEnum.Undergraduate;
	else if (abbreviation === 'pg') return ProductEnum.Postgraduate;
	else if (abbreviation === 'cle') return ProductEnum.Clearing;
	else return ProductEnum.Undergraduate;
};

export const verifyProductAbbreviation = (abbreviatedProduct: string) => {
	return abbreviatedProduct === 'ug' || abbreviatedProduct === 'pg';
};

export const verifyUgso = (ugso: number | null, product: Product | '') => {
	if (!ugso) return null;

	if (product === ProductEnum.Undergraduate || !product) {
		return ugso;
	} else {
		return null;
	}
};

export const verifyCleso = (cleso: number | null, product: Product | '') => {
	if (!cleso) return null;

	if (product === ProductEnum.Clearing || !product) {
		return cleso;
	} else {
		return null;
	}
};

/* Universities included in Tier 1 */
const tier1 = [
	10007785, 10007857, 10007851, 10007150, 10007776, 10007152, 10006299, 10002718,
	10007848, 10007146,
];
/* Universities included in Tier 2 */
const tier2 = [10004797, 10006022];
